import {Component, Input} from '@angular/core';
import {LogoutService} from '@taures/angular-commons';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {UnterschriftService} from "../../services/unterschrift.service";
import {environment} from "../../../environments/environment";
import {ActivationEnd, Router} from "@angular/router";
import {catchError, combineLatestWith, filter, map, startWith, switchMap, tap} from "rxjs/operators";
import {fromEvent, Observable, of} from "rxjs";

@Component({
  selector: 'app-konzept-header',
  templateUrl: './konzept-header.component.html',
  styleUrls: ['./konzept-header.component.scss'],
})
export class KonzeptHeaderComponent {

  private reload$ = fromEvent(window, 'focus').pipe(startWith(null));

  protected anzahlNeueDokumenteForPerson$: Observable<number | null> = this.router.events.pipe(
    filter((data) => data instanceof ActivationEnd),
    map((data: ActivationEnd) => data.snapshot?.params?.personManagementId),
    tap((id: string | undefined) => {
      this.personId = id;
    }),
    combineLatestWith([this.reload$]),
    switchMap(([id])=> {
      if(!id) {
        return of(null)
      }
      return this.unterschriftService.getAnzahlNeueDokumente(id);
    }),
    catchError((_err, caught) => {
      return caught;
    })
  );

  private personId: string | undefined;

  @Input()
  title?: string;

  constructor(readonly logoutService: LogoutService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private router: Router,
              private unterschriftService: UnterschriftService) {
    this.matIconRegistry.addSvgIcon(
      "signature_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/signature_icon.svg")
    );
  }

  openUnterschriftMappe() {
    window.open(`${environment.unterschriftUrl}/person/${this.personId}`);
  }
}
