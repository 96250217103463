import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {combineLatest, delay, merge, Observable, of} from 'rxjs';
import {ConfirmationService, KeycloakTokenService, Person, PersonService, ToastService} from '@taures/angular-commons';
import {KonzeptPageLink} from '../konzept-area/konzept-area.component';
import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {ThemePalette} from '@angular/material/core';
import {ActivatedRoute} from '@angular/router';
import {CrmCustomer, ErstinformationService} from '../../services/erstinformation.service';
import {CustomerStatus, DdeService} from '../../services/dde.service';
import {BrunnenService} from '../../services/brunnen.service';
import {EchseService, EchseState} from '../../services/echse.service';
import {ActiveSoftfairProducts, SoftfairService} from '../../services/softfair.service';
import {MscPushService, MscStatus, UebertragungStatus} from '../../services/msc-push.service';
import log from 'loglevel';
import * as moment from 'moment';
import {Title} from '@angular/platform-browser';
import {SoftfairPushService, StatusDto} from "../../services/softfair-push.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProblemDetail} from "../../interceptors/problem-detail-interceptor";

type ResponseWithOptionalError<T> = null | (T & { error?: HttpErrorResponse });

export interface KonzeptPageArea {
  title: string;
  errorMessage?: string;
  visible?: boolean;
  links: KonzeptPageLink[]
}

interface KonzeptContext {
  customer?: Person;
  crmCustomer?: CrmCustomer;
  customerStatus?: CustomerStatus;
  echseState?: EchseState;
  activeSoftfairProducts?: ActiveSoftfairProducts;
  mscState?: MscStatus | { status: boolean, message: any };
  softfairPushState?: ResponseWithOptionalError<StatusDto>;
  busy?: Record<string, boolean>,
  refresh?: (v: Partial<KonzeptContext>) => void
}

@Component({
  selector: 'app-konzept-page',
  templateUrl: './konzept-page.component.html',
  styleUrls: ['./konzept-page.component.scss']
})
export class KonzeptPageComponent implements OnInit {
  customer: Observable<Person>;
  areas: KonzeptPageArea[];
  brunnenDownloadSpinner = false;

  @ViewChild('hiddenLink', {static: true})
  hiddenLink: ElementRef;

  constructor(private route: ActivatedRoute,
              readonly titleService: Title,
              readonly personService: PersonService,
              readonly erstinformationService: ErstinformationService,
              readonly ddeService: DdeService,
              readonly brunnenService: BrunnenService,
              readonly echseService: EchseService,
              readonly softfairService: SoftfairService,
              readonly softfairPushService: SoftfairPushService,
              readonly mscPushService: MscPushService,
              readonly toastService: ToastService,
              readonly confirmationService: ConfirmationService,
              readonly keycloakTokenService: KeycloakTokenService,
              readonly changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.load()
  }

  @HostListener('window:focus', ['$event'])
  onWindowFocus() {
    this.load();
  }

  private load() {
    const personManagementId = this.route.params.pipe(map(params => params.personManagementId));

    const loadCustomer = this.customer = personManagementId.pipe(
      startWith(null),
      switchMap(id => this.personService.getPerson(id))
    );

    let loadCRMCustomer = this.customer.pipe(startWith(null), switchMap((p) => {
      return !p ? of(null) : this.erstinformationService.loadCustomer(p.personManagementId)
    }));

    let loadCustomerStatus = this.customer.pipe(startWith(null), switchMap((p) => {
      return !p ? of(null) : this.ddeService.getCustomerStatus(p.personManagementId)
    }));

    let loadEchseState = this.customer.pipe(startWith(null), switchMap((p) => {
      return !p ? of(null) : this.echseService.fetchExternalContractsState(p.personManagementId).pipe(catchError(error => {
        if (error.status === 403) return of({status: null} as EchseState)
      }))
    }));

    let loadSoftfairProducts = this.customer.pipe(startWith(null), switchMap((p) => {
      return !p ? of(null) : this.softfairService.getActiveSoftfairProducts(p.personManagementId).pipe(catchError(error => {
        if (error.status === 403) return of({locked: false, products: []} as ActiveSoftfairProducts)
        // console.log(error.error) => { "success": false, "message": "Dem Benutzer fehlt Berechtigung zur Nutzung vom KonzeptButler." }
        throw error
      }))
    }));

    let loadSoftfairPushStatus: Observable<ResponseWithOptionalError<StatusDto>> = combineLatest([personManagementId, loadCustomerStatus]).pipe(
      switchMap(([personManagementId, ddeStatus]) => {
        return !personManagementId || !ddeStatus || !ddeStatus.completed ? of(null) : this.softfairPushService.getStatus(personManagementId).pipe(catchError((error, src) => {
          if (error.status === 403) return of(null)
          return of({gesperrt: false, zugriffAntragsverwaltung: false, error: error})
        }))
      }));

    let loadMscState = this.customer.pipe(startWith(null), switchMap((p) => {
      return p == null ? of(null) : this.mscPushService.getStatus(p.personManagementId).pipe(
        catchError(error => {
          let message = null
          if (error.status === 403) message = 'Keine Berechtigung zur MSC-Übertragung';
          else if (error.status !== 404) message = 'Störung';
          return of({status: error.status === 404, message});
        }))
    }));

    combineLatest([
      loadCustomer,
      loadCRMCustomer,
      loadCustomerStatus,
      loadEchseState,
      loadSoftfairProducts,
      loadSoftfairPushStatus,
      loadMscState
    ]).pipe(catchError((error, src) => {
      log.error('error while loading customer resource', error, src)
      if (error) this.toastService.queueToastMessage({
        notificationType: 'error',
        message: error.error?.message || error.message
      })
      throw error
    })).subscribe(([customer, crmCustomer, customerStatus, echseState, activeSoftfairProducts, softfairPushState, mscState]) => {
      this.update({
        customer,
        crmCustomer,
        customerStatus,
        echseState,
        activeSoftfairProducts,
        softfairPushState,
        mscState
      })
    })
  }

  update({
           customer,
           crmCustomer,
           customerStatus,
           echseState,
           activeSoftfairProducts,
           softfairPushState,
           mscState
         }: KonzeptContext) {
    const customerId = customer?.id;
    const personManagementId = customer?.personManagementId
    const checks = {
      isBusiness: customer?.anrede === 'Firma',
      isCustomerLoaded: customer != null,
      isDDECompleted: customerStatus?.completed,
      isDDEStarted: customerStatus?.started,
      isCustomerStatusLoaded: customerStatus != null,
      isInteressent: customer?.kundenstatus === 'INTERESSENT',
      isDDEIncomplete: customerStatus?.started && !customerStatus.active,

      isCRMCustomerLoaded: crmCustomer != null,
      isErstinformationCompleted: crmCustomer != null && crmCustomer?.completed,
      isErstinformationIncomplete: crmCustomer != null && !crmCustomer?.completed,
      isReadyForErstinformation: crmCustomer != null && !crmCustomer?.completed && !crmCustomer?.consultant?.chamberOfCommerceMissing,

      isEchseStateLoaded: echseState != null,
      isEchseStateNoContracts: echseState?.status === 'NO_CONTRACTS',
      isEchseStateOpenDecisions: echseState?.status === 'OPEN_DECISIONS'
    }

    if (customer) {
      this.titleService.setTitle(checks.isBusiness
        ? `Konzept erstellen - ${customer.vorname} ${customer.nachname}, ${customer.anrede} - TauRes`
        : `Konzept erstellen - ${customer.nachname}, ${customer.vorname} - TauRes`)
    }

    const refresh = (context: Partial<KonzeptContext>) => this.update({
      customer,
      crmCustomer,
      customerStatus,
      echseState,
      activeSoftfairProducts,
      softfairPushState,
      mscState, ...(context || {})
    });
    log.debug('update', {
      customer,
      crmCustomer,
      customerStatus,
      echseState,
      activeSoftfairProducts,
      softfairPushState,
      mscState,
      checks
    });
    const softfairDispatchEndpoint = `/${checks.isBusiness ? 'businesses' : 'customers'}/${personManagementId}/dispatch/softfair`;
    const softfairPushDispatchEndpoint = `/${checks.isBusiness ? 'businesses' : 'customers'}/${personManagementId}/dispatch/softfair-push`;

    [{
      message: 'Bitte die Datenerfassung abschließen. Eingegebene Daten stehen in anderen Anwendungen sonst nicht zur Verfügung!',
      if: checks.isCustomerStatusLoaded && checks.isDDEIncomplete
    },
      {
        message: 'Bitte schließe zunächst die Erstinformation ab.',
        if: checks.isCRMCustomerLoaded && !checks.isErstinformationCompleted
      },
      {
        if: softfairPushState?.error && softfairPushState.error.error instanceof ProblemDetail,
        message: 'Fehler beim Laden des Status bei Softfair: ' + softfairPushState?.error?.error?.detail,
      },
      {
        if: softfairPushState?.error && !(softfairPushState.error.error instanceof ProblemDetail),
        message: 'Fehler beim Laden des Status bei Softfair: ' + softfairPushState?.error?.error?.message || softfairPushState?.error?.message,
      },
      {
        message: 'Bitte schließe zunächst die Basisdaten initial ab.',
        if: !checks.isBusiness && checks.isCRMCustomerLoaded && checks.isErstinformationCompleted && checks.isCustomerStatusLoaded && !checks.isDDECompleted
      }].filter(m => m.if).forEach((toastError) => {
      this.toastService.queueToastMessage({
        notificationType: 'warning',
        preventDisplayDuplicate: true,
        ...toastError
      })
    })

    const softfairProductsGenerallyDisabled = (!checks.isBusiness && !checks.isDDECompleted) || softfairPushState == null || softfairPushState.error || !checks.isErstinformationCompleted;
    const softfairProductsLoading = (softfairPushState === null && checks.isDDECompleted) || !checks.isCustomerStatusLoaded;

    this.areas = ([
      {
        title: 'Beratungsgrundlagen erfassen',
        visible: true,
        links: [
          {
            title: 'Erstinformation',
            visible: true,
            href: checks.isReadyForErstinformation ? `${environment.firstInformationUrl}/customer/${customer?.personManagementId}` : null,
            target: '_blank',
            class: checks.isCRMCustomerLoaded && !checks.isErstinformationCompleted ? 'bg-highlight' : null,
            loading: !checks.isCRMCustomerLoaded,
            onClick: ($event) => this.startErstInformation($event, {crmCustomer, customer})
          },
          {
            title: 'Basisdaten',
            visible: !checks.isBusiness,
            disabled: checks.isErstinformationIncomplete,
            loading: !(checks.isCustomerStatusLoaded && checks.isCRMCustomerLoaded),
            class: (checks.isDDEStarted || !checks.isDDECompleted) ? 'bg-highlight' : null,
            onClick: ($event) => this.startDDE($event, {customer, customerStatus})
          }
        ]
      },
      {
        title: 'Konzept-Werkzeuge verwenden',
        visible: true,
        links: [
          {
            title: 'Thinksurance',
            visible: checks.isBusiness,
            target: '_blank',
            href: 'https://taures.advisory.thinksurance.de/users',
            disabled: !checks.isCustomerLoaded || !checks.isErstinformationCompleted
          },
          {
            title: 'AlVo-Rechner',
            visible: !checks.isBusiness,
            target: '_blank',
            href: `${environment.retirementCalculatorUrl}/?customer=${customerId}`,
            disabled: !checks.isCustomerLoaded || !checks.isErstinformationCompleted
          },
          {
            title: 'KonzeptButler',
            loading: !checks.isCustomerLoaded || activeSoftfairProducts == null,
            class: activeSoftfairProducts?.locked ? 'bg-highlight' : null,
            disabled: (!checks.isBusiness && !checks.isDDECompleted) || !activeSoftfairProducts?.products.includes(CUSTOMER_LINK_KEY_FL) || !checks.isErstinformationCompleted,
            visible: true,
            href: `${softfairDispatchEndpoint}/${CUSTOMER_LINK_KEY_FL}`,
            target: '_blank'
          },
          {
            title: 'KonzeptButler (beta)',
            loading: softfairProductsLoading,
            class: softfairPushState?.gesperrt ? 'bg-highlight' : null,
            disabled: softfairProductsGenerallyDisabled,
            visible: this.keycloakTokenService.hasRole('user', 'ci_softfair-push-backend'),
            href: `${softfairPushDispatchEndpoint}/KONZEPTBUTLER`,
            target: '_blank'
          },
          {
            title: 'LV-Modul',
            class: activeSoftfairProducts?.locked ? 'bg-highlight' : null,
            loading: !checks.isCustomerLoaded || activeSoftfairProducts == null,
            disabled: !checks.isDDECompleted || !activeSoftfairProducts?.products.includes(CUSTOMER_LINK_KEY_LV),
            visible: !checks.isBusiness,
            href: `${softfairDispatchEndpoint}/${CUSTOMER_LINK_KEY_LV}`,
            target: '_blank'
          },
          {
            title: 'LV-Modul (beta)',
            loading: softfairProductsLoading,
            class: softfairPushState?.gesperrt ? 'bg-highlight' : null,
            disabled: softfairProductsGenerallyDisabled,
            visible: this.keycloakTokenService.hasRole('user', 'ci_softfair-push-backend'),
            href: `${softfairPushDispatchEndpoint}/LV_MODUL`,
            target: '_blank'
          },
          {
            title: 'PKV-Modul',
            class: activeSoftfairProducts?.locked ? 'bg-highlight' : null,
            loading: !checks.isCustomerLoaded || activeSoftfairProducts == null,
            disabled: !checks.isDDECompleted || !activeSoftfairProducts?.products.includes(CUSTOMER_LINK_KEY_PKV),
            visible: !checks.isBusiness,
            href: `${softfairDispatchEndpoint}/${CUSTOMER_LINK_KEY_PKV}`,
            target: '_blank'
          },
          {
            title: 'PKV-Modul (beta)',
            loading: softfairProductsLoading,
            class: softfairPushState?.gesperrt ? 'bg-highlight' : null,
            disabled: softfairProductsGenerallyDisabled,
            visible: this.keycloakTokenService.hasRole('user', 'ci_softfair-push-backend'),
            href: `${softfairPushDispatchEndpoint}/PKV_MODUL`,
            target: '_blank'
          },
          {
            title: 'Antragsverwaltung',
            class: activeSoftfairProducts?.locked ? 'bg-highlight' : null,
            loading: !checks.isCustomerLoaded || activeSoftfairProducts == null,
            disabled: !checks.isDDECompleted || !activeSoftfairProducts?.products.includes(CUSTOMER_LINK_KEY_ANTRAG),
            visible: !checks.isBusiness,
            href: `${softfairDispatchEndpoint}/${CUSTOMER_LINK_KEY_ANTRAG}`,
            target: '_blank'
          },
          {
            title: 'Antragsverwaltung (beta)',
            loading: softfairProductsLoading,
            class: softfairPushState?.gesperrt ? 'bg-highlight' : null,
            disabled: softfairProductsGenerallyDisabled || !softfairPushState.zugriffAntragsverwaltung ,
            visible: this.keycloakTokenService.hasRole('user', 'ci_softfair-push-backend'),
            href: `${softfairPushDispatchEndpoint}/ANTRAGSVERWALTUNG`,
            target: '_blank'
          },
          {
            title: 'Fremdverträge',
            visible: !checks.isBusiness,
            loading: !checks.isCustomerLoaded || !checks.isEchseStateLoaded,
            disabled: !checks.isDDECompleted || checks.isEchseStateNoContracts || echseState?.status == null,
            class: checks.isEchseStateOpenDecisions ? 'bg-highlight' : null,
            href: `${environment.echseUrl}/customers/${personManagementId}`
          },
          {
            title: 'MSC-Übertragung',
            visible: !checks.isBusiness,
            disabled: mscState?.status === UebertragungStatus.NICHT_UEBERTRAGBAR,
            loading: mscState == null,
            onClick: ($event) => this.uebertrageKundeToMsc($event, {customer, refresh}),
            hint: mscState?.message
          },
        ]
      },
      {
        title: 'Konzept erstellen',
        visible: !checks.isBusiness,
        links: [
          {
            title: 'Brunnen',
            visible: !checks.isBusiness,
            disabled: !checks.isDDECompleted,
            loading: !checks.isCRMCustomerLoaded || this.brunnenDownloadSpinner,
            onClick: ($event) => this.startBrunnenDownload($event, {customer})
          }
        ]
      },
      {
        title: 'Dokument erstellen',
        visible: true,
        links: [
          {
            title: 'Maklervertrag',
            visible: true,
            disabled: !checks.isBusiness && !checks.isDDECompleted,
            href: `${environment.docsUrl}/customers/${customerId}/broker-contract`,
            target: '_blank',
            color: 'accent' as ThemePalette,
            loading: !checks.isCustomerLoaded
          },
          {
            title: 'Vollmacht',
            visible: true,
            disabled: !checks.isBusiness && !checks.isDDECompleted,
            href: `${environment.docsUrl}/customers/${customerId}/proxy`,
            target: '_blank',
            color: 'accent' as ThemePalette,
            loading: !checks.isCustomerLoaded
          },
          {
            title: 'Bestätigung zur Risikovoranfrage',
            visible: !checks.isBusiness,
            disabled: !checks.isDDECompleted,
            href: `${environment.rvaUrl}/customers/${customerId}/risk-assessment-confirmation`,
            target: '_blank',
            color: 'accent' as ThemePalette,
            loading: !checks.isCustomerLoaded
          }
        ]
      }
    ] as KonzeptPageArea[]).filter(a => a.visible).map(a => ({...a, links: a.links.filter(l => l.visible)}))

    this.changeDetector.markForCheck()
  }

  startErstInformation($event: MouseEvent, {customer, crmCustomer}: KonzeptContext): void {
    if (!crmCustomer) return;
    if (crmCustomer.consultant.chamberOfCommerceMissing) {
      $event.preventDefault();
      this.confirmationService.showPopup({
        message: 'Erstellung der Erstinformation nicht möglich,' +
          ' da die Angabe zur IHK des Beraters in den VP-Stammdaten fehlt. Bitte ergänze zunächst die fehlenden Daten.',
        confirmationType: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
        closable: false
      })
    } else if (crmCustomer.completed) {
      $event.preventDefault();
      this.confirmationService.showDialog({
        message: `Die Erstinformation wurde bereits am ` +
          `${moment(crmCustomer.firstInformationReceived).format('DD.MM.YYYY')} ` +
          `${crmCustomer?.firstInformationCreatedBy ? 'durch ' + crmCustomer.firstInformationCreatedBy : ''} erstellt.`,
        confirmButtonText: 'Erneut versenden'
      }).afterClosed().subscribe(sendAgain => {
        if (sendAgain) window.location.href = environment.firstInformationUrl + '/customer/' + customer.personManagementId
      })
    }
  }

  startDDE($event: MouseEvent, {customer, customerStatus}: KonzeptContext): void {
    $event.preventDefault();
    const link = environment.ddeUrl + '/' + customer.personManagementId;
    if (customerStatus?.lastAccessByName) {
      this.confirmationService.showPopup({
        message: `Die Kundendaten werden aktuell von ${customerStatus.lastAccessByName} bearbeitet. Der Aufruf ist daher nicht möglich.`,
        confirmationType: 'warning',
        confirmButtonText: 'OK',
        showCancelButton: false,
        closable: false
      })
    } else {
      if (customerStatus?.active) {
        this.confirmationService.showPopup({
          message: 'Die Datenerfassung ist möglicherweise bereits in einem anderen Browser oder Tab geöffnet!',
          confirmButtonText: 'Trotzdem fortfahren',
          confirmationType: 'warning'
        }).afterClosed().subscribe((closed) => {
          if (closed) {
            window.open(link, '_blank')
          }
        })
      } else {
        window.open(link, '_blank');
      }
    }
  }

  startBrunnenDownload($event: MouseEvent, {customer}: KonzeptContext) {
    $event.preventDefault()
    if (!customer) return
    this.brunnenDownloadSpinner = true;
    this.brunnenService.downloadBrunnen(customer.id)
      .subscribe(file => {
        if (file) {
          const url = URL.createObjectURL(file);
          const anchor = this.hiddenLink.nativeElement as HTMLAnchorElement;
          anchor.setAttribute('href', url);
          anchor.setAttribute('download', file.name);
          anchor.click();
        }
        this.brunnenDownloadSpinner = false;
        this.changeDetector.markForCheck();
      });
  }


  uebertrageKundeToMsc($event: MouseEvent, {customer, refresh}: KonzeptContext) {
    $event.preventDefault()
    this.changeDetector.markForCheck();
    refresh({mscState: null})
    this.mscPushService
      .uebertrageKunde(customer.personManagementId)
      .pipe(catchError((error) => {
        if (error) this.toastService.queueToastMessage({
          notificationType: 'error',
          message: error.error?.message || error.message
        })
        throw error;
      }))
      .subscribe(() => {
        this.toastService.queueToastMessage({
          message: 'Die Übertragung an das MSC war erfolgreich.',
          notificationType: "success"
        });
        this.load()
      }, () => {
        this.load()
      });
  }
}

export const CUSTOMER_LINK_KEY_PKV = 'PKV_MODUL';
export const CUSTOMER_LINK_KEY_LV = 'LV_MODUL';
export const CUSTOMER_LINK_KEY_ANTRAG = 'ANTRAG_MODUL';
export const CUSTOMER_LINK_KEY_FL = 'FL_3_0';
