import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

export class ProblemDetail {
  readonly type: string;
  readonly status?: number;
  readonly title?: string;
  readonly detail?: string;
  readonly instance?: string;
  readonly extensions: any;

  constructor(problemDetailBody: any) {
    const {
      type,
      status,
      title,
      detail,
      instance,
      ...extensions
    } = problemDetailBody;
    this.type = type;
    this.status = typeof status === "number" ? status : Number.parseInt(status);
    this.title = title;
    this.detail = detail;
    this.instance = instance;
    this.extensions = extensions || {};
  }

  public getExtension(name: string): any {
      return name in this.extensions ? this.extensions[name] : undefined;
  }
}

@Injectable()
export class ProblemDetailInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (this.isProblemDetailResponse(err)) {
          throw new HttpErrorResponse({
            error: new ProblemDetail(err.error),
            headers: err.headers,
            status: err.status,
            statusText: err.statusText,
            url: err.url,
          });
        }
        throw err;
      })
    );
  }

  private isProblemDetailResponse(err: HttpErrorResponse) {
    return err.headers.get("Content-Type") === "application/problem+json" && err.error instanceof Object;
  }
}
